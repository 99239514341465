@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label > label {
    color: $textColor;
  }
  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }
  &-item {
    margin-bottom: 30px;
  }
}

.#{$prefix}-item-label {
  margin-right: 1.5em;
  min-width: fit-content;
}

.#{$prefix}-item-label > label {
  &::after {
    content: ' :';
    white-space: pre;
  }
}