@import 'variables';

$prefix: $antPrefix + -tag;

.#{$prefix} {
  background: transparent;
  border-color: $borderColorA06;
  a,
  a:hover {
    color: $textColorSecondary;
  }
}

.#{$prefix}-blue {
  color: $secondaryColor;
}

.#{$prefix}-orange {
  color: $primaryColor;
}

.#{$prefix}-red {
  color: $errorColor;
}

.#{$prefix}-green {
  color: $addColor;
}

.#{$prefix}-magenta {
  color: #9D278B;
}

.dashboard-event-tag {
  display: block;
  width: 100%;
  border: none;
  text-align: center;
  padding: 5px 0;
  font-size: 13px;
  border-radius: 5px 5px 0 0;
  background: $secondaryColor;
  color: #fff;
}