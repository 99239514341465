@import 'variables';

$prefix: $antPrefix + -radio;

.#{$prefix} {
  &-wrapper,
  & {
    color: $textColor;
  }
  &-wrapper:hover .#{$prefix}-inner,
  &:hover .#{$prefix}-inner,
  &-input:focus + .#{$prefix}-inner {
    border-color: $primaryColor;
  }
  &-inner {
    background-color: white;
    border-color: rgb(170, 168, 168);
  }
  &-checked {
    &:after {
      border-color: $primaryColor;
    }
    .#{$prefix}-inner {
      border-color: $primaryColor;
      background-color: white;
      &::after {
        background-color: $primaryColor;
      }
    }
  }
  &-indeterminate .#{$prefix}-inner {
    background-color: $bodyBackground;
    border-color: $borderColor;
    &::after {
      background-color: $primaryColor;
    }
  }
  &-disabled .#{$prefix}-inner {
    background-color: $bodyBackground;
  }
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
  background: $componentBackground;
  color: $textColor;
}


.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $contrastBackground;
  color: $primaryColor;
}

