@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix}, .#{$prefix}.has-breadcrumb {
  background: $clientColor;
  margin-top: 10px; 
  padding: 12px 24px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &-heading-title,
  &-back-button,
  & > .#{$antPrefix}-breadcrumb a:not(:hover),
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    color: $textColorSecondary;
  }
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    cursor: auto;
  }
}

.#{$prefix}-heading-title {
  font-weight: 300;
  font-size: 21px;
}

.#{$antPrefix}-breadcrumb a, .#{$antPrefix}-breadcrumb > span:last-child a, .#{$antPrefix}-breadcrumb-separator {
  color: $textColorSecondary;
  text-transform: uppercase;
  font-size: .8em;
  letter-spacing: .1em;
}

.#{$antPrefix}-breadcrumb a, .#{$prefix}-back-button {
  transition: color 0.3s;
  &:hover {
    color: $primaryColor;
  }
}