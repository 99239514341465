@import 'variables';

$prefix: $antPrefix + -statistic;

.#{$prefix} {
    &-title {
        color: $textColorSecondary;
    }
}

#black-statistic {
    .ant-statistic-title {
        color: #000;
    }
}