@import '~antd/dist/antd.css';
@import 'button';
@import 'card';
@import 'checkbox';
@import 'collapse';
@import 'descriptions';
@import 'divider';
@import 'form';
@import 'input';
@import 'layout';
@import 'list';
@import 'modal';
@import 'notifications';
@import 'pagination';
@import 'picker';
@import 'popover';
@import 'progress';
@import 'radio';
@import 'result';
@import 'select';
@import 'spin';
@import 'steps';
@import 'switch';
@import 'table';
@import 'tabs';
@import 'tag';
@import 'time-picker';
@import 'tree';
@import 'menu';
@import 'dropdown';
@import 'typography';
@import 'empty';
@import 'pageheader';
@import 'upload';
@import 'skeleton';
@import 'scrollbar';
@import 'linkedin';
@import 'statistic';
@import 'comment';

@font-face {
    font-family: "Century Gothic";
    src: local("Century Gothic"), local("CenturyGothic"),
        url("../../fonts/GOTHIC.TTF") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Century Gothic";
    src: local("Century Gothic"), local("CenturyGothic"),
       url("../../fonts/GOTHICB.TTF") format("truetype");
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: "Century Gothic";
}