@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }
      &-item-label {
        background: $contrastBackground;
      }
    }
  }
  &-title,
  &-item-label,
  &-item-content {
    color: $textColor;
  }
}

.#{$prefix}-view {
  padding: 0px 16px;
}

.#{$prefix}-item-container {
  flex-direction: column;
  margin-bottom: 10px;
}

.#{$prefix}-item-label {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: .9em;
  margin-bottom: 5px;

  &::after {
    content: '';
  }
}

.#{$prefix}-item-content {
  font-size: 1.1em;

  .#{$antPrefix}-typography {
    color: $secondaryColor;
  }
}

.#{$prefix}-small {
  
  .#{$prefix}-view {
    padding: 0;
  }

  .#{$prefix}-row > th, .#{$prefix}-row > td {
    padding-bottom: 0;
  }

  .#{$prefix}-header {
    margin-bottom: 5px;
  }

  .#{$prefix}-title {
    font-size: 15px;
  }  

  .#{$prefix}-item-content {
    font-size: 1em;
  }

  .#{$prefix}-item-label {
    font-size: .8em;
    position: relative;
    top: 2px;
    color: $secondaryColor;
  }

  .#{$prefix}-item-container {
    margin-bottom: 4px;
    flex-direction: row;
  }
}

.#{$prefix} {
  max-width: 100%;
}

.show-project-tabs .#{$prefix} .#{$prefix}-header {
  flex-wrap: wrap;
  .#{$prefix}-extra {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .#{$prefix}-title {
    white-space: pre-wrap;
  }
  .#{$prefix}-row {
    display: flex;
    flex-direction: column;
  }
}