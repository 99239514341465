@import 'variables';

$prefix: $antPrefix + -comment;

.#{$prefix}-inner {
    flex-wrap: wrap;
  
    span {
      width: 100%;
    }
  }
  
.#{$prefix}-content-author-time {
  white-space: normal;
}

.#{$prefix}-inner span {
  width: auto;
}