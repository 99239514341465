@import 'variables';

$prefix: $antPrefix + -divider;

.#{$prefix} {
  &-horizontal {
    &.#{$prefix} {
      &-with-text-center,
      &-with-text-left,
      &-with-text-right {
        color: $textColor;
        &::before,
        &::after {
          border-color: $textColor;
        }
      }
    }
  }
}

.#{$antPrefix}-layout-header .#{$prefix}-vertical {
  border-color: $clientColor;
  height: 2.8em;
  top: 0;
}