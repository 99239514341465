@import 'variables';

$prefix: $antPrefix + -list;

.#{$prefix} {
  color: $textColor;
  &-item-meta {
    &-title,
    &-description {
      color: $textColor;
    }
  }
  &-item {
    border-color: $borderColor !important;
    display: block !important;
  }
}
#noBlock .#{$prefix} {
  color: $textColor;
  &-item-meta {
    &-title,
    &-description {
      color: $textColor;
    }
  }
  &-item {
    border-color: $borderColor !important;
    display: flex !important;
  }
}