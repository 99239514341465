@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix}, .#{$antPrefix}-form-item  {
  color: $textColor;

  .#{$prefix}.#{$prefix}-drag {
    background: $componentBackground;
    border-radius: 5px;

    &:hover {
      border-color: $primaryColor;
    }

    p.#{$prefix}-text {
      color: $textColor;
      font-size: 15px;
    }

    p.#{$prefix}-drag-icon {
      margin-bottom: 12px;

      .anticon {
        font-size: 40px;
      }

    }
  }
}
