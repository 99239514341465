@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor !important;
  line-height: 35px;
  &-borderless {
    background-color: $componentBackground !important;
    & > .#{$prefix}-item > .#{$prefix}-content {
      background: transparent !important;
    }
  }

  & > .#{$prefix}-item > .#{$prefix}-header {
    color: $textColor !important;
  }

  &-content {
    color: $textColor !important;
    background: $contrastBackground !important;
  }
}

.#{$prefix}-content-box .#{$prefix}-content-box {
  padding: 0 !important;
}

.#{$prefix} > .#{$prefix}-item:last-child > .#{$prefix}-header {
  padding: 8px 8px 8px 40px;
  border-radius: 0 0 5px 5px;
}

.#{$prefix}-header-text td.#{$antPrefix}-descriptions-item {
  padding-bottom: 0;
}

.#{$prefix}-header-text .#{$antPrefix}-descriptions-view {
  padding: 0;
}

.#{$prefix}-header-text .#{$antPrefix}-descriptions-header {
  margin-bottom: 12px;
}

.#{$prefix}-header-text .#{$antPrefix}-descriptions-extra {
  display: flex;
  align-items: center;
}

.#{$prefix}-header-text .#{$antPrefix}-descriptions-item-label {
  font-size: .8em;
}

.#{$prefix}-header-text .#{$antPrefix}-descriptions-item-content {
  font-size: 1em;
}

.#{$prefix}-content-box .#{$antPrefix}-card {
  padding: 0 5px 0 8px;
  margin-bottom: 1em;
}

.#{$prefix}-content-box .#{$antPrefix}-tabs {
  margin-right: 0;
}

.register-form .#{$prefix} {
  border-radius: 5px;
  background: #fff;
  margin-bottom: 20px;
  .#{$prefix}-item { border-radius: 5px; }
  .#{$prefix}-content {
    border-radius: 0 0 5px 5px;
    background: rgba(0, 0, 0, 0.02) !important;
    .#{$prefix}-content-box {
      border-radius: 0 0 5px 5px;
      .ant-form-item { margin-bottom: 20px; }
    }
  }
  .#{$prefix}-arrow { top: 1px !important; }
}

.#{$prefix}.item-header-collapse {
  > .#{$prefix}-item > .#{$prefix}-header {
    display: block;
    cursor: default;

    .#{$prefix}-header-text {
      cursor: default;
    }
  }
}
