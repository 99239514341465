@import 'variables';

$prefix: $antPrefix + -pagination;
$disabledClasses: '.#{$prefix}-disabled a, .#{$prefix}-disabled:hover a, .#{$prefix}-disabled:focus a, .#{$prefix}-disabled .#{$prefix}-item-link, .#{$prefix}-disabled:hover .#{$prefix}-item-link, .#{$prefix}-disabled:focus .#{$prefix}-item-link';

.#{$prefix} {
  color: $textColor;
  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next,
  &-item {
    .#{$prefix}-item-link {
      background-color: transparent;
    }
    &:not(.#{$prefix}-disabled) {
      color: $textColor;
      background-color: transparent;
      border-color: $borderColor;
      &:hover {
        border-color: $primaryColor;
        a {
          border-color: $primaryColor;
        }
      }
      .#{$prefix}-item-link,
      a {
        border-color: $borderColor;
        color: $textColor;
      }
    }
    &-container,
    .#{$prefix}-item-container {
      .#{$prefix}-item-ellipsis {
        color: $disabledColor;
        a {
          color: $disabledColor;
        }
      }
      .#{$prefix}-item-link-icon {
        color: $primaryColor;
      }
    }
  }
  &-item {
    &-active {
      border-color: $primaryColor;
      a {
        color: $primaryColor;
      }
    }
  }
}
#{$disabledClasses} {
  color: $disabledColor;
  border-color: $borderColor;
}

.#{$prefix}-item {
  line-height: 20px;
  border: none;
  height: 35px;
  width: 24px;
}

.#{$prefix}-item .#{$antPrefix}-tag {
  height: 100%;
  width: 100%;
  padding: 4px 7px;
}

.#{$prefix}-prev .#{$prefix}-item-link, .#{$prefix}-next .#{$prefix}-item-link {
  border: none;
}

.#{$prefix}-item-link {
  position: relative;
  top: 3px;
}